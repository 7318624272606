import simg from "../images/service/1.jpg";
import simg2 from "../images/service/2.jpg";
import simg3 from "../images/service/3.jpg";
import simg4 from "../images/service/4.jpg";
import simg5 from "../images/service/5.jpg";
import simg6 from "../images/service/6.jpg";

import sIcon1 from "../images/icon/kitchen-utensils.png";
import sIcon2 from "../images/icon/gas-stove.png";
import sIcon3 from "../images/icon/water-tap.png";
import sIcon4 from "../images/icon/sink.png";
import sIcon5 from "../images/icon/basement.png";
import sIcon6 from "../images/icon/remodeling.png";

import sSingleimg1 from "../images/service-single/oprava/1.jpg";
import sSingleimg2 from "../images/service-single/oprava/2.jpg";

import sSingleimg3 from "../images/service-single/instalace/1.jpg";
import sSingleimg4 from "../images/service-single/instalace/2.jpg";

import sSingleimg5 from "../images/service-single/kontrola/1.jpg";
import sSingleimg6 from "../images/service-single/kontrola/2.jpg";

import sSingleimg7 from "../images/service-single/topenarskesluzby/1.jpg";
import sSingleimg8 from "../images/service-single/topenarskesluzby/2.jpg";

import sSingleimg9 from "../images/service-single/instalacetopnych/1.jpg";
import sSingleimg10 from "../images/service-single/instalacetopnych/2.jpg";

import sSingleimg11 from "../images/service-single/tepelnacerpadla/1.jpg";
import sSingleimg12 from "../images/service-single/tepelnacerpadla/2.jpg";

const Services = [
  {
    Id: "1",
    sImg: simg,
    sIcon: sIcon1,
    sTitle: "Oprava kotlů",
    description:
      "Poskytuji opravy kotlů. Záruční servis pouze na značky kotlů Wolf, Brotje, Thermona a Baxi.",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "2",
    sImg: simg2,
    sIcon: sIcon2,
    sTitle: "Instalace kotlů",
    description:
      "Zajišťuji dodávky a instalaci nových kotlů, jak na plyn tak i na dřevo. Je možné využít dotační programy vztahující se na instalace kotlů.",
    ssImg1: sSingleimg3,
    ssImg2: sSingleimg4,
  },
  {
    Id: "3",
    sImg: simg3,
    sIcon: sIcon3,
    sTitle: "Kontrola a údržba",
    description:
      "Provádím pravidelné kontroli a údržbu kotlů, což je klíčové pro bezpečný a efektivní provoz.",
    ssImg1: sSingleimg5,
    ssImg2: sSingleimg6,
  },
  {
    Id: "4",
    sImg: simg4,
    sIcon: sIcon4,
    sTitle: "Topenářské služby",
    description:
      "Nabízím kompletní topenářské služby včetně instalace, údržby a oprav topných systémů.",
    ssImg1: sSingleimg7,
    ssImg2: sSingleimg8,
  },
  {
    Id: "5",
    sImg: simg5,
    sIcon: sIcon5,
    sTitle: "Instalace topných systémů",
    description:
      "Poskytuji dodávky a instalaci radiátorů, podlahových topení, kotlů atd. Zajistím montáž a optimalizaci topného systému pro dosažení maximální efektivity.",
    ssImg1: sSingleimg9,
    ssImg2: sSingleimg10,
  },
  {
    Id: "6",
    sImg: simg6,
    sIcon: sIcon6,
    sTitle: "Tepelná čerpadla",
    description: "Dodávky, montáž a servis tepelných čerpadel značky Wolf.",
    ssImg1: sSingleimg11,
    ssImg2: sSingleimg12,
  },
];
export default Services;
