import React from "react";
import { Link } from "react-router-dom";

const Hero3 = () => {
  return (
    <section className="wpo-hero-section-2">
      <div className="container">
        <div className="row">
          <div className="col col-xs-5 col-lg-5 offset-lg-7 col-12">
            <div className="wpo-hero-section-text">
              <div className="wpo-hero-subtitle">
                <span>:: PETR PODOLSKÝ </span>
              </div>
              <div className="wpo-hero-title">
                <h2>
                  <span>TOP</span>ENÁŘ LUŽE.
                </h2>
              </div>
              <div className="wpo-hero-des">
                <p>
                  Jsem certifikovaný topenář s více než{" "}
                  <b>25 lety zkušeností.</b>
                  <br /> Poskytuji služby v oblasti topenářství pro Vás.
                </p>
              </div>
              <div className="btns">
                <ul>
                  <li>
                    <Link to="/kontakt" className="btn theme-btn">
                      KONTAKT
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right-vec">
        <div className="right-img"></div>
      </div>
    </section>
  );
};

export default Hero3;
