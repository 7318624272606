import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const Benefits = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="wpo-benefits-section">
      <div className="row">
        <div className="col-lg-12 col-12">
          <div className="wpo-benefits-item">
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  Netopí vám radiátor?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <ol>
                    <li>
                      <b>Zkontrolujte termostatickou hlavici:</b> Ujistěte se,
                      že je nastavena na vyšší teplotu.
                    </li>
                    <li>
                      <b>Odvzdušnění radiátoru:</b> Pomocí odvzdušňovacího klíče
                      uvolněte vzduchové bubliny. Jakmile začne unikat voda,
                      ventil opět zavřete.
                    </li>
                    <li>
                      <b>Zkontrolujte ventil:</b> Ujistěte se, že je plně
                      otevřený.
                    </li>
                    <li>
                      <b>Zkontrolujte tlak vody v systému:</b> Tlak by měl být
                      mezi 1 a 2 bary. Pokud je nízký, doplňte vodu.
                    </li>
                    <li>
                      <b>Zkontrolujte kotel:</b> Ujistěte se, že funguje správně
                      a je nastaven na požadovanou teplotu.
                    </li>
                    <li>
                      <b>Zkontrolujte oběhové čerpadlo:</b> Ujistěte se, že
                      čerpadlo funguje správně.
                    </li>
                    <li>
                      <b>Vyčištění radiátoru:</b> Pokud je zanesený, může být
                      nutné jej propláchnout.
                    </li>
                    <li>
                      <b>Kontrola zpětných ventilů:</b> Zkontrolujte, zda jsou v
                      otevřené poloze.
                    </li>
                  </ol>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.heading}>
                  Co zkontrolovat v případě, že netopí kotel (nefunguje)?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <ol>
                    <li>
                      <b>Zkontrolujte napájení:</b> Ujistěte se, že je kotel
                      zapnutý a připojený k napájení.
                    </li>
                    <li>
                      <b>Nastavte správnou teplotu:</b> Ujistěte se, že je
                      nastavená teplota dostatečně vysoká.
                    </li>
                    <li>
                      <b>Zkontrolujte tlak vody:</b> Tlak by měl být mezi 1 a 2
                      bary. Pokud je nízký, doplňte vodu do systému.
                    </li>
                    <li>
                      <b>Resetujte kotel:</b> Některé kotle mají tlačítko reset.
                      Zkuste kotel resetovat podle návodu k použití.
                    </li>
                    <li>
                      <b>Zkontrolujte termostat:</b> Ujistěte se, že je
                      termostat funkční a správně nastaven.
                    </li>
                    <li>
                      <b>Zkontrolujte oběhové čerpadlo:</b> Ujistěte se, že
                      čerpadlo funguje správně a není zaseknuté.
                    </li>
                    <li>
                      <b>Vyčištění kotle:</b> Pravidelná údržba a čištění mohou
                      předejít mnoha problémům.
                    </li>
                  </ol>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
