import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "../HomePage";
import ServicePage from "../ServicePage/ServicePage";
import ServicePageS2 from "../ServicePageS2/ServicePageS2";
import LoginPage from "../LoginPage";
import SignUpPage from "../SignUpPage";
import ForgotPassword from "../ForgotPassword";
import ServiceSinglePage from "../ServiceSinglePage/ServiceSinglePage";
import ProjectPage from "../ProjectPage/ProjectPage";
import ProjectSinglePage from "../ProjectSinglePage/ProjectSinglePage";
import TeamSinglePage from "../TeamSinglePage/TeamSinglePage";
import TermPage from "../TermPage/TermPage";
import TestimonialPage from "../TestimonialPage/TestimonialPage";
import PricingPage from "../PricingPage/PricingPage";
import ErrorPage from "../ErrorPage/ErrorPage";
import ContactPage from "../ContactPage/ContactPage";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="team-single/:id" element={<TeamSinglePage />} />
          <Route path="sluzby" element={<ServicePage />} />
          <Route path="sluzby/:id" element={<ServiceSinglePage />} />
          <Route path="project" element={<ProjectPage />} />
          <Route path="project-single/:id" element={<ProjectSinglePage />} />
          <Route path="terms" element={<TermPage />} />
          <Route path="testimonial" element={<TestimonialPage />} />
          <Route path="kontakt" element={<ContactPage />} />
          <Route path="pricing" element={<PricingPage />} />
          <Route path="404" element={<ErrorPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<SignUpPage />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
