import React, { Fragment } from "react";

import Features from "../../components/Features/Features";
import Footer from "../../components/footer/Footer";
import FunFact2 from "../../components/FunFact2/FunFact2";
import Hero3 from "../../components/hero3/hero3";
import Navbar from "../../components/Navbar/Navbar";
import Scrollbar from "../../components/scrollbar/scrollbar";
import ServiceSection from "../../components/ServiceSection/ServiceSection";

import Logo from "../../images/logo.png";

const HomePage3 = () => {
  return (
    <Fragment>
      <Navbar topbarBlock={"wpo-header-style-2"} Logo={Logo} />
      <Hero3 />
      <Features fClass={"wpo-features-section-s2"} />
      <ServiceSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default HomePage3;
