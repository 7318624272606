import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo2.png";
import Services from "../../api/service";
import Projects from "../../api/project";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};
const SubmitHandler = (e) => {
  e.preventDefault();
};

const Footer = (props) => {
  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <img src={Logo} alt="blog" />
                </div>
                <p></p>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>KONTAKT </h3>
                </div>
                <div className="contact-ft">
                  <ul>
                    <li>
                      <i className="fi flaticon-location"></i>Hamzova 411
                      <br /> Luže, 538 54
                    </li>
                    <li>
                      <a href="tel:+420 607 910 569">
                        <i className="fi flaticon-phone-call"></i>+420 607 910
                        569
                      </a>
                    </li>
                    <li>
                      <a href="mailto:kotelnikcz@seznam.cz">
                        <i className="fi flaticon-send"></i>kotelnikcz@seznam.cz
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>SLUŽBY </h3>
                </div>
                <ul>
                  {Services.slice(0, 5).map((service, srv) => (
                    <li key={srv}>
                      <Link onClick={ClickHandler} to={`/sluzby/${service.Id}`}>
                        {service.sTitle}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright"> Copyright &copy; 2024 Petr Podolský </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
