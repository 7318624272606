import React from "react";
import ContactForm from "../ContactFrom/ContactForm";

const Contactpage = () => {
  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-10 offset-lg-1">
            <div className="office-info">
              <div className="row">
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-placeholder"></i>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Adresa </h2>
                      <p>Hamzova 411, Luže </p>
                    </div>
                  </div>
                </div>

                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <a href="mailto:kotelnikcz@seznam.cz">
                    <div className="office-info-item">
                      <div className="office-info-icon">
                        <div className="icon">
                          <i className="fi flaticon-email"></i>
                        </div>
                      </div>

                      <div className="office-info-text">
                        <h2>Email</h2>
                        <p>kotelnikcz@seznam.cz</p>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <a href="tel:+420607910569">
                    <div className="office-info-item">
                      <div className="office-info-icon">
                        <div className="icon">
                          <i className="fi flaticon-phone-call"></i>
                        </div>
                      </div>
                      <div className="office-info-text">
                        <h2>Volejte</h2>
                        <p>+420 607 910 569</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="wpo-contact-map-section">
        <div className="wpo-contact-map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2570.375945248779!2d16.028536499999998!3d49.89174489999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470dbf50ab4c652b%3A0xffe0f63718e0a7ac!2sPodolsk%C3%BD%20Petr!5e0!3m2!1scs!2scz!4v1722619178748!5m2!1scs!2scz"></iframe>
        </div>
      </section>
    </section>
  );
};

export default Contactpage;
