import React from "react";
import Services from "../../api/service";
import { Link } from "react-router-dom";

const ServiceSidebar = (props) => {
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="col-lg-4 order-lg-1 order-md-2 order-2">
      <div className="blog-sidebar">
        <div className="widget category-widget">
          <h3>Služby</h3>
          <ul>
            {Services.slice(0, 6).map((service, Sitem) => (
              <li key={Sitem}>
                <Link onClick={ClickHandler} to={`/sluzby/${service.Id}`}>
                  {service.sTitle}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ServiceSidebar;
