import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams } from "react-router-dom";
import Services from "../../api/service";
import { Link } from "react-router-dom";
import Benefits from "./benefits";
import ServiceSidebar from "./sidebar";
import Footer from "../../components/footer/Footer.js";

const ServiceSinglePage = (props) => {
  const { id } = useParams();

  const serviceDetails = Services.find((item) => item.Id === id);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-5"} />
      <PageTitle pageTitle={serviceDetails.sTitle} pagesub={"Služby"} />
      <div className="wpo-project-details-area wpo-service-details section-padding">
        <div className="container">
          <div className="row">
            <ServiceSidebar />
            <div className="col-lg-8 order-lg-2 order-md-1 order-1">
              <div className="wpo-minimals-wrap">
                <div className="minimals-img">
                  <img src={serviceDetails.sImg} alt="" />
                </div>
              </div>
              <div className="wpo-p-details-section">
                <h2>{`${serviceDetails.sTitle}`}</h2>
                <p>{serviceDetails.description}</p>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-12">
                    <div className="wpo-p-details-img">
                      <img src={serviceDetails.ssImg1} alt="" />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-12">
                    <div className="wpo-p-details-img">
                      <img src={serviceDetails.ssImg2} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpo-faq-section">
                <h4>Nejčastější dotazy?</h4>
                <Benefits />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServiceSinglePage;
